export default [
    {
        title: "Dashboard",
        route: "inicio",
        icon: "DashIcon",
    },
    {
        title: "Solicitudes material",
        route: "solicitud-material",
        icon: "SoliIcon",
    },
    {
        title: "Ordenes",
        route: "ordenes",
        icon: "OrdeIcon",
    },
    {
        title: "Despachos",
        route: "despachos",
        icon: "DespIcon",
        children: [
            {
                title: "Gestión conductores",
                route: "gestion-conductores",
                icon: null,
            },
            {
                title: "Gestión vehículos",
                route: "gestion-vehiculos",
                icon: null,
            },
        ],
    },
    {
        title: "Actas de cierre",
        route: "acta-cierre",
        icon: "DespIcon",
    },
    {
        title: "Inventario",
        route: "inventario",
        icon: "InveIcon",
        children: [
            {
                title: "Gestión disponibilidad",
                route: "gestion-disponibilidad",
                icon: null,
            },
        ],
    },
    {
        title: "Producción",
        route: "produccion",
        icon: "ProdIcon",
        children: [
            {
                title: "Planeación",
                route: "planeacion",
                icon: null,
            },
            {
                title: "Gestión de Especies",
                route: "gestion-especies",
                icon: null,
            },
            {
                title: "Gestión de Empaques",
                route: "gestion-empaques",
                icon: null,
            },
            {
                title: "Gestión de productos",
                route: "gestion-productos",
                icon: null,
            },
            {
                title: "Plan de entregas",
                route: "plan-entregas",
                icon: null,
            },
        ],
    },
    {
        title: "Reportes",
        route: "reportes",
        icon: "RepoIcon",
        children: [
            {
                title: "Eliminación",
                route: "reporte-eliminacion",
                icon: null,
            },
            {
                title: "Perdidas",
                route: "reporte-perdidas",
                icon: null,
            },
            {
                title: "Egresos",
                route: "reporte-egresos",
                icon: null,
            },
            {
                title: "Contratos",
                route: "reporte-contratos",
                icon: null,
            },
        ],
    },
    {
        title: "Administración",
        route: "administracion",
        icon: "SettingsIcon",
        children: [
            {
                title: "Usuarios",
                route: "usuarios",
                icon: null,
            },
            {
                title: "Roles y permisos",
                route: "profiles",
                icon: null,
            },
            {
                title: "Integraciones",
                route: "integraciones",
                icon: null,
                children: [
                    {
                        title: "Cuencas",
                        route: "cuencas",
                        icon: null,
                    },
                    {
                        title: "Info. divipola",
                        route: "info-divipola",
                        icon: null,
                    },
                    {
                        title: "Regionales",
                        route: "regionales",
                        icon: null,
                    },
                    {
                        title: "Terceros",
                        route: "terceros",
                        icon: null,
                    },
                    {
                        title: "Contratos",
                        route: "contratos",
                        icon: null,
                    },
                ],
            },
            {
                title: "Gestión de predios",
                route: "gestion-predios",
                icon: null,
            },
            {
                title: "Gestión de viveros",
                route: "gestion-viveros",
                icon: null,
            },
            {
                title: "Contratos admón",
                route: "contratos-admon",
                icon: null,
            },
        ],
    },
];
